
























import Vue from 'vue'
import { ISnackbar } from '@/store/modules/Snackbar'

export default Vue.extend({
	name: 'SnackBar',
	computed: {
		snackBar(): Array<ISnackbar> {
			return this.$store.getters['snackbar/getSnackbar']
		},
	},
	methods: {
		destroySnackbar(payload: ISnackbar): void {
			this.$store.dispatch('snackbar/removeSnackbar', payload)
		},
	},
})
