import ApiClient from '@/services/apiClient'
import authService from '@/services/authService'
import { AxiosRequestConfig } from 'axios'

class DanaApi extends ApiClient {
	private readonly token: string

	constructor(apiVersion: string = process.env.VUE_APP_DANA_API_VERSION) {
		super(process.env.VUE_APP_DANA_API, apiVersion, 5000)

		//set token
		this.token = authService.getToken()

		//before each request set token to header
		this.axios.interceptors.request.use((config: AxiosRequestConfig) => {
			config.headers = {
				authorization: this.token,
			}

			return config
		})
	}
}

export default DanaApi
