








import Vue from 'vue'
import UserSectionMenu from '@/layout/UserSectionMenu.vue'

export default Vue.extend({
	name: 'Header',
	components: { UserSectionMenu },
	props: {
		toggleSidebar: {
			type: Boolean,
		},
	},
	methods: {
		getToolbarHeight(): void {
			let height = '0px'
			const toolbar = this.$refs.toolbar

			if (toolbar) {
				height = `${((toolbar as Vue).$el as HTMLElement).offsetHeight}px`
			}

			if (height) {
				document.documentElement.style.setProperty('--headerHeight', height)
			} else {
				console.error(`couldn't set height of the header`)
			}
		},
	},
	mounted() {
		this.getToolbarHeight()
	},
})
