import getters from '@/store/modules/Snackbar/getters'
import actions from '@/store/modules/Snackbar/actions'
import mutations from '@/store/modules/Snackbar/mutations'

export interface ISnackbar {
	active: boolean
	color: string
	message: string
	//timeout: number
}

export type ISnackbarState = Array<ISnackbar>

const state: ISnackbarState = []

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
