













































import Vue from 'vue'
import LineChart from '@/components/charts/LineChart.vue'
import Chart, { ChartData, ChartOptions } from 'chart.js'
import moment from 'moment-timezone'
import ApiClient from '@/services/apiClient'
import { DanaQuery, DanaResponse } from '@/types/dana'
import ColorSwatches from '@/mixins/colorSwatches'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'K2Rtp',
	components: { LineChart },
	mixins: [ColorSwatches],
	data() {
		return {
			timezone: process.env.VUE_APP_TIMEZONE as string,
			k2Rtp: null as null | number,
			k2RtpChat: {
				loading: false,
				data: {
					datasets: [],
				} as ChartData,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									callback(value: number | string): string | number | null | undefined {
										return value + '% '
									},
								},
							},
						],
					},
					tooltips: {
						callbacks: {
							label(tooltipItem: Chart.ChartTooltipItem): string | string[] {
								return tooltipItem.value + '%'
							},
						},
					},
				} as ChartOptions,
			},
		}
	},
	methods: {
		async getK2Summary() {
			let sinceDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				date.setMonth(0)
				date.setDate(1)

				return moment(new Date(date)).format('YYYY-MM-DD')
			}

			let toDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				date.setDate(0)
				date.setMonth(11)

				return moment(date).format('YYYY-MM-DD')
			}

			this.k2RtpChat.loading = true

			try {
				const response = await new DanaApi().get<DanaResponse.ISummaryGranulated, DanaQuery.ISummaryGranulated>(
					'/stats/summary',
					{
						since_date: sinceDate(),
						to_date: toDate(),
						machine_id: undefined,
						granulation_tier: 'month',
						game_system: 'K2',
					},
				)

				let labels = []
				let chartData: number[] = []
				let rawData: { [key: string]: number } = {}

				for (let obj of response.spins) {
					if (obj.year && obj.month) {
						if (obj.rtp) {
							rawData[moment(new Date(obj.year, obj.month - 1, 1)).format('MMMM, YYYY')] =
								Math.round((obj.rtp + Number.EPSILON) * 100) / 100
						}
					}
				}

				for (let i = 0; i < 12; i++) {
					const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

					date.setMonth(i + 1)
					date.setDate(0)

					labels.push(moment(date).format('MMMM, YYYY'))
				}

				for (const obj of labels) {
					if (rawData[obj]) {
						chartData.push(rawData[obj])
					} else {
						chartData.push(0)
					}
				}

				this.k2RtpChat.data.labels = labels

				this.k2RtpChat.data.datasets?.push({
					label: 'RTP',
					data: chartData,
					fill: false,
					backgroundColor: 'rgb(25, 118, 210)',
					borderColor: 'rgb(25, 118, 210)',
					lineTension: 0,
				})
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.k2RtpChat.loading = false

			try {
				const response = await new DanaApi().get<DanaResponse.ISummaryGranulated, DanaQuery.ISummaryGranulated>(
					'/stats/summary',
					{
						since_date: sinceDate(),
						to_date: toDate(),
						machine_id: undefined,
						game_system: 'K2',
					},
				)

				if (response.spins.length > 0) {
					if (response.spins[0].rtp) {
						this.k2Rtp = Math.round((response.spins[0].rtp + Number.EPSILON) * 100) / 100
					}
				}
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}
		},
	},
	created() {
		this.getK2Summary()
	},
})
