import { MutationTree } from 'vuex'
import { ISnackbar, ISnackbarState } from '@/store/modules/Snackbar/index'

export enum snackbarMutation {
	ADD_SNACKBAR = 'addSnackbar',
	REMOVE_SNACKBAR = 'removeSnackbar',
}

const mutations: MutationTree<ISnackbarState> = {
	[snackbarMutation.ADD_SNACKBAR](state, payload: ISnackbar): void {
		state.push(payload)
	},
	[snackbarMutation.REMOVE_SNACKBAR](state, payload: ISnackbar): void {
		console.log(payload)
		state.splice(
			state.findIndex((el) => el.message === payload.message),
			1,
		)
	},
}

export default mutations
