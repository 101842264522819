import Vue from 'vue'
import { dateColorSwatch, rangeColorSwatch, singleValueColorSwatch } from '@/utils/colorSwatches'

export default Vue.mixin({
	methods: {
		singleValueColorSwatch: (value: number, min?: number, max?: number, median?: number) =>
			singleValueColorSwatch(value, min, max, median),
		rangeColorSwatch: (value: number, success?: Array<Array<number>>, warning?: Array<Array<number>>) =>
			rangeColorSwatch(value, success, warning),
		dateColorSwatch: (value: string, success: string, warning?: string, featureLock = false) =>
			dateColorSwatch(value, success, warning, featureLock),
	},
})
