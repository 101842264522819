































import Vue from 'vue'
import BarChart from '@/components/charts/BarChart.vue'
import Chart, { ChartData, ChartOptions } from 'chart.js'
import { formatCurrencyWithSpaces } from '@/utils/helpers'
import { DanaQuery, DanaResponse } from '@/types/dana'
import moment from 'moment-timezone'
import ApiClient from '@/services/apiClient'
import ColorSwatches from '@/mixins/colorSwatches'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'Netto',
	components: { BarChart },
	mixins: [ColorSwatches],
	data() {
		return {
			timezone: process.env.VUE_APP_TIMEZONE as string,
			currency: process.env.VUE_APP_CURRENCY as string,
			netto: null as number | null,
			nettoChart: {
				loading: false,
				data: {
					datasets: [],
				} as ChartData,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									callback(value: number | string): string | number | null | undefined {
										return formatCurrencyWithSpaces(value) + ' ' + process.env.VUE_APP_CURRENCY
									},
								},
							},
						],
					},
					tooltips: {
						callbacks: {
							label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
								if (tooltipItem.value) {
									return formatCurrencyWithSpaces(tooltipItem.value) + ' ' + process.env.VUE_APP_CURRENCY
								} else {
									return tooltipItem.value + ' ' + process.env.VUE_APP_CURRENCY
								}
							},
						},
					},
				} as ChartOptions,
			},
		}
	},
	methods: {
		formatCurrency: formatCurrencyWithSpaces,
		async getNetto(): Promise<void> {
			let sinceDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				date.setMonth(0)
				date.setDate(1)

				return moment(new Date(date)).format().substr(0, 10)
			}

			let toDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				date.setDate(0)
				date.setMonth(11)

				return moment(date).format().substr(0, 10)
			}

			this.nettoChart.loading = true

			try {
				const response = await new DanaApi().get<DanaResponse.ISummaryGranulated, DanaQuery.ISummaryGranulated>(
					'/stats/summary',
					{
						since_date: sinceDate(),
						to_date: toDate(),
						machine_id: undefined,
						granulation_tier: 'month',
					},
				)

				let labels = []
				let chartData: number[] = []
				let rawData: { [key: string]: number } = {}

				for (let obj of response.bookkeepings) {
					if (obj.year && obj.month) {
						rawData[moment(new Date(obj.year, obj.month - 1, 1)).format('MMMM, YYYY')] = obj.netto / 100
					}
				}

				for (let i = 0; i < 12; i++) {
					const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

					date.setMonth(i + 1)
					date.setDate(0)

					labels.push(moment(date).format('MMMM, YYYY'))
				}

				for (const obj of labels) {
					if (rawData[obj]) {
						chartData.push(rawData[obj])
					} else {
						chartData.push(0)
					}
				}

				this.nettoChart.data.labels = labels

				this.nettoChart.data.datasets?.push({
					label: 'Netto',
					data: chartData,
					fill: false,
					backgroundColor: 'rgb(25, 118, 210)',
				})
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.nettoChart.loading = false

			try {
				const response = await new DanaApi().get<DanaResponse.ISummaryGranulated, DanaQuery.ISummaryGranulated>(
					'/stats/summary',
					{
						since_date: sinceDate(),
						to_date: toDate(),
						machine_id: undefined,
					},
				)

				if (response.bookkeepings.length > 0) {
					this.netto = response.bookkeepings[0].netto / 100
				}
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}
		},
	},
	created() {
		this.getNetto()
	},
})
