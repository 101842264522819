







































































import Vue from 'vue'
import AuthService from '@/services/authService'
import { FormDefinition } from '@/types/formDefinition'
import { requiredRule } from '@/utils/formRules'

interface IForm extends FormDefinition {
	rules: {
		email: ((message?: string) => true | string)[]
		password: ((message?: string) => true | string)[]
	}
}

export default Vue.extend({
	name: 'Login',
	data() {
		return {
			appName: process.env.VUE_APP_TITLE,
			showPassword: false,
			loading: false,
			user: {
				email: null,
				password: null,
			},
			error: {
				error: false,
				message: '',
			},
			form: {
				valid: false,
				rules: {
					email: [requiredRule(`Please enter your e-mail`)],
					password: [requiredRule(`Please enter your password`)],
				},
			} as IForm,
		}
	},
	methods: {
		async login() {
			if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
				this.loading = true

				//TODO email and password can't be null because of form validation
				//@ts-ignore
				await AuthService.authenticate(this.user.email, this.user.password)
					.then(() => {
						this.loading = false

						this.$router.push('/')
					})
					.catch((error) => {
						this.user.password = null
						;(this.$refs.form as Vue & { resetValidation: () => void }).resetValidation()

						this.loading = false

						this.error.error = true

						if (!error.response) {
							this.error.message = error.message
						} else if (error.code === 'ECONNABORTED') {
							this.error.message = error.message
						} else if (error.response.status === 400) {
							if (error.response.data) {
								this.error.message = error.response.data.detail
							} else {
								this.error.message = 'Something went wrong'
							}
						}
					})
			}
		},
	},
})
