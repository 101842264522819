import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import modules from '@/store/modules'

Vue.use(Vuex)

export interface IRootState {
	version: string
}

const index: StoreOptions<IRootState> = {
	strict: true,
	state: { version: '1.0.0' },
	modules,
}

export default new Vuex.Store<IRootState>(index)
