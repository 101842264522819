import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
	routes,
	mode: 'history',
	//base: process.env.BASE_URL,
})

router.beforeEach((to, from, next) => {
	const loggedIn = localStorage.getItem('user')

	if (to.path !== '/login' && !loggedIn) {
		next('/login')
	} else {
		next()
	}
})

export default router
