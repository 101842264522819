
import Vue, { PropType } from 'vue'
import { Bar, mixins as chartMixins } from 'vue-chartjs'
import { ChartOptions } from 'chart.js'

export default Vue.extend({
	name: 'BarChart',
	extends: Bar,
	mixins: [chartMixins.reactiveProp],
	props: {
		options: {
			type: Object as PropType<ChartOptions>,
		},
	},
	mounted(): void {
		//TODO issue with vue js & it's typescript support, does not recognize the function from extends & properties from mixins
		//@ts-ignore
		this.renderChart(this.chartData, this.options)
	},
})
