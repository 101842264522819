import axios, { AxiosRequestConfig } from 'axios'
import authService from '@/services/authService'

const schindlerUrl = process.env.VUE_APP_SCHINDLER_API

const schindlerApi = axios.create({
	baseURL: schindlerUrl,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json',
	},
	timeout: 5000,
})

schindlerApi.interceptors.request.use((config: AxiosRequestConfig) => {
	const token = authService.getToken()

	if (token) {
		config.headers = {
			...config.headers,
			authorization: 'Bearer ' + token,
		}
	}

	return config
})

export default schindlerApi
