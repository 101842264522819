import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends object, Y extends PropertyKey>(
	obj: X,
	prop: Y,
): obj is X & Record<Y, unknown> {
	// eslint-disable-next-line no-prototype-builtins
	return obj.hasOwnProperty(prop)
}

export function formatDate(date: string | null, format: string): string | null {
	if (date) {
		return moment(date).format(format)
	} else {
		return null
	}
}

export function formatCurrencyWithSpaces(value: number | string): string {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
