












































import Vue from 'vue'
import Netto from '@/components/Home/Netto.vue'
import TotalMachines from '@/components/Home/TotalMachines.vue'
import K2Rtp from '@/components/Home/K2Rtp.vue'
import K3Rtp from '@/components/Home/K3Rtp.vue'
import Payout from '@/components/Home/Payout.vue'
import router from '@/router'

export default Vue.extend({
	name: 'Home',
	components: { Payout, K3Rtp, K2Rtp, TotalMachines, Netto },
	data() {
		return {
			customerName: process.env.VUE_APP_CUSTOMER_NAME,
			timezone: process.env.VUE_APP_TIMEZONE as string,
		}
	},
	computed: {
		rtpK2(): boolean {
			return process.env.VUE_APP_K2 === 'true'
		},
		rtpK3(): boolean {
			return process.env.VUE_APP_K3 === 'true'
		},
		rtp(): string {
			if (process.env.VUE_APP_K3 === 'true' && process.env.VUE_APP_K2 === 'true') {
				return 'col-12 col-md-12 col-lg-6'
			} else {
				return 'col-12'
			}
		},
	},
	created() {},
})
