import { ActionTree } from 'vuex'
import { IRootState } from '@/store'
import { ISnackbar, ISnackbarState } from '@/store/modules/Snackbar/index'
import { snackbarMutation } from '@/store/modules/Snackbar/mutations'

export enum snackbarActions {
	ADD_SNACKBAR = 'addSnackbar',
	REMOVE_SNACKBAR = 'removeSnackbar',
}
//TODO perhaps remove, only duplicate of mutation cuz nothing special is done in this functions
const actions: ActionTree<ISnackbarState, IRootState> = {
	[snackbarActions.ADD_SNACKBAR]({ commit }, payload: ISnackbar): void {
		commit(snackbarMutation.ADD_SNACKBAR, payload)
	},
	[snackbarActions.REMOVE_SNACKBAR]({ commit }, payload: ISnackbar): void {
		commit(snackbarMutation.REMOVE_SNACKBAR, payload)
	},
}

export default actions
