
























import Vue from 'vue'
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import ApiClient from '@/services/apiClient'
import { DanaResponse } from '@/types/dana'
import { ChartData, ChartOptions } from 'chart.js'
import { IChartElementOptions } from '@/types/charts'
import DanaApi from '@/services/danaApi'

export default Vue.extend({
	name: 'TotalMachines',
	components: {
		DoughnutChart,
	},
	data() {
		return {
			totalMachines: null as number | null,
			totalMachinesChart: {
				loading: false,
				data: {
					labels: [],
					datasets: [],
				} as ChartData,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					elements: {} as IChartElementOptions,
				} as ChartOptions,
			},
		}
	},
	methods: {
		async getMachines() {
			try {
				this.totalMachinesChart.loading = true

				const response = await new DanaApi().get<DanaResponse.IMachines, unknown>('/info/machines')

				const multigames: { [key: string]: number } = {}

				const colors: { [key: string]: string } = {}

				/**
				 * this might help for adding new colors:
				 * https://color.adobe.com/create/color-wheel
				 * https://vuetifyjs.com/en/styles/colors/#material-colors
				 */
				const availableColors: { [key: string]: string } = {
					Unknown: 'rgb(224, 224, 224)',
					'Red planet': 'rgb(211, 47, 47)',
					'Green planet': 'rgb(56, 142, 60)',
					'Yellow planet': 'rgb(251, 192, 45)',
					'Blue planet': 'rgb(25, 118, 210)',
					'Black planet': 'rgb(0, 0, 0)',
				}

				for (const obj of response.machines) {
					if (obj.multigame) {
						if (!multigames[obj.multigame.name]) {
							multigames[obj.multigame.name] = 1

							if (availableColors[obj.multigame.name]) {
								colors[obj.multigame.name] = availableColors[obj.multigame.name]
							} else {
								colors[obj.multigame.name] = ''
							}
						} else {
							multigames[obj.multigame.name]++
						}
					} else {
						if (!multigames['Unknown']) {
							multigames['Unknown'] = 1

							colors['Unknown'] = availableColors['Unknown']
						} else {
							multigames['Unknown']++
						}
					}
				}

				for (const labels in multigames) {
					this.totalMachinesChart.data.labels?.push(labels)
				}

				let chartData = []
				for (const data in multigames) {
					chartData.push(multigames[data])
				}

				let chartColors = []
				for (const backgroundColor in colors) {
					chartColors.push(colors[backgroundColor])
				}

				this.totalMachinesChart.data.datasets?.push({
					label: 'Total Machines',
					data: chartData,
					backgroundColor: chartColors,
				})

				let total = 0
				for (const totalKey in multigames) {
					total += multigames[totalKey]
				}

				;(this.totalMachinesChart.options.elements as IChartElementOptions).center = {
					text: total.toString(),
				}
			} catch (error) {
				if (error) {
					console.error(error)
				}
			}

			this.totalMachinesChart.loading = false
		},
	},
	created() {
		this.getMachines()
	},
})
