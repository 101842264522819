import { GetterTree } from 'vuex'
import { IRootState } from '@/store'
import { ISnackbar } from '@/store/modules/Snackbar/index'

const getters: GetterTree<ISnackbar, IRootState> = {
	getSnackbar(state): ISnackbar {
		return state
	},
}

export default getters
