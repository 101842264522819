
import Vue, { PropType } from 'vue'
import { Doughnut, mixins as chartMixins } from 'vue-chartjs'
import { ChartOptions } from 'chart.js'

/**
 * sources for plugin:
 * http://demo.vue-chartjs.org/
 * http://jsfiddle.net/kdvuxbtj/
 * https://jsfiddle.net/ellisdod/5efa8yo9/
 * https://github.com/chartjs/Chart.js/issues/78
 */

/**
 * Plugin for text in the center
 * @param chart
 *
 * Usage:
 * options: {
 *   elements: {
 *       center: {
 *       text: 'Desktop',
 *       color: '#36A2EB', //Default black
 *       fontStyle: 'Helvetica', //Default Arial
 *       sidePadding: 15 //Default 20 (as a percentage)
 *     }
 *   }
 * }
 *
 */
const plugin = function (chart: any) {
	if (chart.config.options.elements.center) {
		//Get ctx from string
		const ctx = chart.chart.ctx

		//Get options from the center object in options
		const centerConfig = chart.config.options.elements.center
		const fontStyle = centerConfig.fontStyle || 'Arial'
		const txt = centerConfig.text
		const color = centerConfig.color || '#000'
		const sidePadding = centerConfig.sidePadding || 20
		const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
		//Start with a base font of 30px
		ctx.font = '30px ' + fontStyle

		//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
		const stringWidth = ctx.measureText(txt).width
		const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

		// Find out how much the font can grow in width.
		const widthRatio = elementWidth / stringWidth
		const newFontSize = Math.floor(30 * widthRatio)
		//changed this constant bellow from 2 to 1, it seems to align better on center with only one number displayed
		const elementHeight = chart.innerRadius * 1

		// Pick a new font size, so it will not be larger than the height of label.
		const fontSizeToUse = Math.min(newFontSize, elementHeight)

		//Set font settings to draw it correctly.
		ctx.textAlign = 'center'
		ctx.textBaseline = 'middle'
		const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
		const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
		ctx.font = fontSizeToUse + 'px ' + fontStyle
		ctx.fillStyle = color

		//Draw text in center
		ctx.fillText(txt, centerX, centerY)
	}
}

export default Vue.extend({
	name: 'DoughnutChart',
	extends: Doughnut,
	mixins: [chartMixins.reactiveProp],
	props: {
		options: {
			type: Object as PropType<ChartOptions>,
		},
	},
	mounted(): void {
		//TODO issue with vue js & it's typescript support, does not recognize the function from extends & properties from mixins
		//@ts-ignore
		this.addPlugin({
			id: 'DoughnutPlugin',
			beforeDraw: plugin,
		})
		//TODO issue with vue js & it's typescript support, does not recognize the function from extends & properties from mixins
		//@ts-ignore
		this.renderChart(this.chartData, this.options)
	},
})
