




























import Vue from 'vue'
import MenuItems from '@/layout/MenuItems.vue'

export default Vue.extend({
	name: 'Sidebar',
	components: {
		MenuItems,
	},
	props: {
		value: {
			type: Boolean,
			default: () => true,
			required: true,
		},
		barColor: {
			type: String,
			default: () => 'rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)',
		},
		sideBarImage: {
			type: String,
		},
		appLogo: {
			type: String,
		},
		appName: {
			type: String,
		},
	},
})
