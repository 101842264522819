
























import Vue from 'vue'
import Header from '@/layout/Header.vue'
import Sidebar from '@/layout/Sidebar.vue'
import * as AppLogo from '@/assets/logo.png'
import * as SidebarImage from '@/assets/neutronstarj.jpg'
import SnackBar from '@/components/SnackBar.vue'

export default Vue.extend({
	name: 'App',
	components: { SnackBar, Sidebar, Header },
	data() {
		return {
			//depends on setup breakpoint prop, in sidebar->v-navigation-drawer
			toggleSidebar: this.$vuetify.breakpoint.lgAndUp,
			appName: process.env.VUE_APP_TITLE,
			appLogo: AppLogo,
			sideBarImage: SidebarImage,
		}
	},
})
