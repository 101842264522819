




















import Vue from 'vue'
import authService from '@/services/authService'

export default Vue.extend({
	name: 'UserSectionMenu',
	methods: {
		logout(): void {
			authService.logout()
			this.$router.push('/login')
		},
	},
})
