import axios, { AxiosInstance, AxiosResponse } from 'axios'
import router from '@/router'
import authService from '@/services/authService'
import qs from 'qs'

interface IAxiosResponseWithResult<T = any> extends AxiosResponse {
	data: {
		result: T
	}
}

/**
 * TODO add repeat request on timeout and network error
 * TODO need to rename to ApiClient, make wrapper DanaApi around
 *
 * Wrapper around the axios, should be always extended with another wrapper class
 */
class ApiClient {
	protected axios: AxiosInstance

	constructor(
		baseUrl: string | undefined = undefined,
		apiVersion: string | undefined = undefined,
		timeout: number | undefined = undefined,
	) {
		//create axios instance
		this.axios = axios.create({
			baseURL: `${baseUrl}${'/' + apiVersion}`,
			timeout: timeout,
		})
	}

	public async get<T, U>(path: string, query?: U, autoErrorHandle = false): Promise<T> {
		const rawQuery = qs.stringify(query, {
			arrayFormat: 'repeat',
			indices: false,
		})

		const params = new URLSearchParams(rawQuery)

		try {
			const response = await this.axios.get<T, IAxiosResponseWithResult<T>>(path, {
				params,
			})

			return response.data.result
		} catch (error) {
			return Promise.reject(this.errorAutoHandler(error, autoErrorHandle))
		}
	}

	public async post<T>(path: string, body?: T, autoErrorHandle = false): Promise<T> {
		try {
			const response = await this.axios.post<T, IAxiosResponseWithResult<T>>(path, body)

			return response.data.result
		} catch (error) {
			return Promise.reject(this.errorAutoHandler(error, autoErrorHandle))
		}
	}

	public async put<T>(path: string, body?: T, autoErrorHandle = false): Promise<T> {
		try {
			const response = await this.axios.put<T, IAxiosResponseWithResult<T>>(path, body)

			return response.data.result
		} catch (error) {
			return Promise.reject(this.errorAutoHandler(error, autoErrorHandle))
		}
	}

	public async delete<T>(path: string, body?: T, autoErrorHandle = false): Promise<T> {
		try {
			const response = await this.axios.delete<T, IAxiosResponseWithResult<T>>(path, body)

			return response.data.result
		} catch (error) {
			return Promise.reject(this.errorAutoHandler(error, autoErrorHandle))
		}
	}

	private errorAutoHandler(error: unknown, autoErrorHandle: boolean): Error | undefined | unknown {
		if (!autoErrorHandle) {
			if (axios.isAxiosError(error)) {
				if (!error.response) {
					router
						.push({
							name: 'Error',
							params: {
								//TODO dunno why it dont like string[], but for sure doesn't work as string
								//@ts-ignore
								pathMatch: router.app.$route.path.substring(1).split('/'),
								errorMessage: error.message,
								request: error.request.responseURL,
							},
							query: router.app.$route.query,
						})
						//TODO look for better solution I guess. cannot happen in production so should be fine
						.catch((error) => {
							console.error(error)
						})

					return undefined
				} else {
					let errorPage = false

					switch (error.response.status) {
						case 500:
							errorPage = true
							break
						case 401:
							authService.destroyToken()

							router.push('Login').catch((error) => {
								console.error(error)
							})

							return undefined
						case 403:
							errorPage = true
							break
						case 404:
							errorPage = true
							break
						case 424:
							errorPage = true
							break
					}

					if (errorPage) {
						router
							.push({
								name: 'Error',
								params: {
									//TODO dunno why it dont like string[], but for sure doesn't work as string
									//@ts-ignore
									pathMatch: router.app.$route.path.substring(1).split('/'),
									errorMessage: error.response.status + ' ' + error.response.data.description,
									request: error.request.responseURL,
								},
								query: router.app.$route.query,
							})
							//TODO look for better solution I guess. cannot happen in production so should be fine
							.catch((error) => {
								console.error(error)
							})

						return undefined
					} else {
						return error
					}
				}
			} else {
				return error
			}
		} else {
			return error
		}
	}
}

export default ApiClient
