import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate'

import './scss/main.scss'

Vue.config.productionTip = false

//didn't figure out how to register it as plugin, bad documentation
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
setInteractionMode('eager')

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app')
